import $ from 'jquery';
import Rails from '@rails/ujs';

window.submitIEForm = (event) => {
  event.preventDefault();
  const name = $(event.target).attr('form');
  if (name) {
    const form = document.querySelector(`form#${name}`);
    if (form.dataset.remote === 'true') {
      Rails.fire(document.querySelector(`form#${name}`), 'submit');
    } else {
      $(form).trigger('submit');
    }
  }
};

window.submitAndClose = (event) => {
  $(event.target).parents('.ui.modal').modal('hide');
  window.submitIEForm(event);
};

export const titleize = function (string) {
  const words = string.replace(/_/g, ' ').split(' ');
  const array = [];
  for (let i = 0; i < words.length; ++i) {
    array.push(words[i].charAt(0).toUpperCase() + words[i].toLowerCase().slice(1));
  }
  return array.join(' ');
};

export const isHidden = (elem) =>
  elem.classList.contains("hide") ||
  elem.classList.contains("hidden") ||
  elem.classList.contains("tw-hidden") ||
  elem.classList.contains("invisible") ||
  elem.classList.contains("tw-invisible") ||
  elem.style.display === "none";

export const toggleContent = (elem, show, displayClass = "tw-block") => {
  if (show && isHidden(elem)) {
    elem.classList.remove("tw-hidden");
    elem.classList.add(displayClass);
    if (elem.classList.contains("hidden")) {
      elem.classList.remove("hidden");
    }
    if (elem.classList.contains("hide")) {
      elem.classList.remove("hide");
    }
    if (elem.style.display === "none") {
      elem.style.display = "unset";
    }
  }
  if (!show && !isHidden(elem)) {
    elem.classList.add("tw-hidden");
    elem.classList.remove(displayClass);
  }
};

export const isiOS = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
].includes(navigator.platform);

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
  // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
