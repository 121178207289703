import { Controller } from "@hotwired/stimulus";
import { isHidden } from "../../src/custom/Utils";

export default class extends Controller {
  static targets = ["nameDisplayContainer", "nameDisplay", "nameField"];
  toggleEditName = () => {
    if (isHidden(this.nameFieldTarget)) {
      this.nameFieldTarget.classList.remove("tw-hidden");
      this.nameFieldTarget.focus();
      this.nameFieldTarget.setSelectionRange(
        this.nameFieldTarget.value.length,
        this.nameFieldTarget.value.length
      );
      this.nameDisplayContainerTarget.classList.add("tw-hidden");
    } else {
      this.nameFieldTarget.classList.add("tw-hidden");
      this.nameDisplayContainerTarget.classList.remove("tw-hidden");
    }
  };

  setExpenseReportName = (e) => {
    if (e?.detail?.select?.id === "third_party_id-container") {
      this.nameFieldTarget.value = e.detail.target.dataset.name;
      this.nameDisplayTarget.innerText = e.detail.target.dataset.name;
      this.nameDisplayContainerTarget.classList.remove("tw-hidden");
      this.nameFieldTarget.classList.add("tw-hidden");
    }
  };

  scrollIntoView = (e) => {
    e.preventDefault();
    const target = document.getElementById(e.currentTarget.getAttribute("href").slice(1));
    target.scrollIntoView({ behavior: "smooth" });
  }
}
