import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  updateTotal() {
    let total = 0.0;

    this.element.querySelectorAll('input[type="checkbox"]:checked').forEach((checkbox) => {
      const recordId = checkbox.dataset.recordId;
      const item = this.element.querySelector(`div[data-record-id="${recordId}"]`);

      if (item) {
        total += parseFloat(item.dataset.amount) || 0;
      } else {
        console.log(`[TotalAmountController] Item div with record_id ${recordId} not found.`);
      }
    });

    const totalAmountDiv = document.getElementById("total-amount");

    if (totalAmountDiv) {
      totalAmountDiv.textContent = `$${total.toFixed(2)}`;
    } else {
      console.log("[TotalAmountController] Total amount div not found.");
    }
  }
}
