import { getFilestackUrlForFile, defaultFilestackOptions } from './Filestack';

export default class QuillFilestackUploader {
  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    this.range = null;

    if (typeof this.options.filestack === 'undefined') {
      // eslint-disable-next-line no-console
      console.warn(
        '[Missing config] filestack client must be passed to image uploader',
      );
    }

    const defaults = defaultFilestackOptions();

    this.filestack = options.filestack;
    this.picker = this.filestack.picker({
      transformationsUI: false,
      fromSources: this.options.fromSources || defaults.fromSources,
      maxFiles: 1,
      uploadInBackground: false,
      accept: this.options.accept || defaults.accept,
      onUploadDone: (res) => {
        const file = res.filesUploaded[0];
        this.insertToEditor(file);
      },
    });

    const toolbar = this.quill.getModule('toolbar');
    toolbar.addHandler('image', this.selectImage.bind(this));

    this.handleDrop = this.handleDrop.bind(this);
    this.handlePaste = this.handlePaste.bind(this);

    this.quill.root.addEventListener('drop', this.handleDrop, false);
    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  selectImage() {
    this.range = this.quill.getSelection();
    this.picker.open();
  }

  handleDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    if (
      evt.dataTransfer
      && evt.dataTransfer.files
      && evt.dataTransfer.files.length
    ) {
      if (document.caretRangeFromPoint) {
        const selection = document.getSelection();
        const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.startContainer,
            range.startOffset,
            range.startContainer,
            range.startOffset,
          );
        }
      } else {
        const selection = document.getSelection();
        const range = document.caretPositionFromPoint(evt.clientX, evt.clientY);
        if (selection && range) {
          selection.setBaseAndExtent(
            range.offsetNode,
            range.offset,
            range.offsetNode,
            range.offset,
          );
        }
      }

      this.range = this.quill.getSelection();
      const file = evt.dataTransfer.files[0];

      setTimeout(() => {
        this.range = this.quill.getSelection();
        this.uploadToFilestack(file)
          .then((result) => {
            this.insertToEditor(result);
          })
          .catch((err) => {
            window.Sentry.captureException(err);
          });
      }, 0);
    }
  }

  handlePaste(evt) {
    const clipboard = evt.clipboardData || window.clipboardData;

    if (clipboard && (clipboard.items || clipboard.files)) {
      const items = clipboard.items || clipboard.files;
      const IMAGE_MIME_REGEX = /^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i;

      for (let i = 0; i < items.length; i += 1) {
        if (IMAGE_MIME_REGEX.test(items[i].type)) {
          const file = items[i].getAsFile ? items[i].getAsFile() : items[i];

          if (file) {
            this.uploadToFilestack(file)
              .then((result) => {
                this.range = this.quill.getSelection();
                if (!this.range) {
                  setTimeout(() => this.insertToEditor(result), 0);
                }
              })
              .catch((err) => {
                window.Sentry.captureException(err);
              });
          }
        }
      }
    }
  }

  uploadToFilestack(file) {
    if (!file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)) {
      return Promise.resolve();
    }
    return this.filestack.upload(file);
  }

  insertToEditor(file) {
    const { range } = this;
    this.quill.insertEmbed(range.index, 'image', `${getFilestackUrlForFile(file)}`);
    range.index += 1;
    this.quill.setSelection(range.index, 'api');
  }
}

window.QuillFilestackUploader = QuillFilestackUploader;
