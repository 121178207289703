import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["pointValue", "pointContainer"];

  connect() {
    const options = {
      series: [], // Empty series to be populated with data from data_point_component
      chart: {
        type: "bar",
        height: 66, // Base required height is 46px
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          // borderRadius: 10,
          // borderRadiusApplication: "end",
          // borderRadiusWhenStacked: "last",
          barHeight: "100%", // Ensures the bar fills the height of the chart
          dataLabels: {
            total: {
              enabled: false, // Disable total data labels
            },
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: [this.pointContainerTarget.dataset.name],
        labels: {
          show: false, // Hide x-axis labels
        },
        axisBorder: {
          show: false, // Hide x-axis border
        },
        axisTicks: {
          show: false, // Hide x-axis ticks
        },
      },
      yaxis: {
        labels: {
          show: false, // Hide y-axis labels
        },
      },
      dataLabels: {
        enabled: false, // Disable individual data labels
      },
      grid: {
        show: false, // Hide grid lines
        position: "back",
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      tooltip: {
        enabled: false, // Disable tooltip for a cleaner look
      },
      legend: {
        show: false, // Hide legend
        width: 0,
        height: 0,
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
      },
      fill: {
        opacity: 1,
      },
    };
    this.pointValueTargets.forEach((t) => {
      options.series.push({
        name: t.dataset.name,
        data: [parseInt(t.dataset.value, 10)],
        color: t.dataset.color,
      });
    });
    const el = this.pointContainerTarget;
    const chart = new ApexCharts(el, options);
    chart.render();
  }
}
