import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    id: Number,
    expenseReportId: String,
    amount: Number,
    errors: Array,
  }

  connect() {
    if (this.amountValue == 100) {
      this.setSuccess()
    } else if (this.errorsValue.length > 0) {
      this.setError()
    }
  }

  setSuccess() {
    this.dispatch("success", { detail: { id: this.idValue, expenseReportId: this.expenseReportIdValue }});
  }

  setError() {
    this.dispatch("error", { detail: { id: this.idValue, errors: this.errorsValue }});
  };
}
