import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["saving", "success", "error", "errorMessages"];
  static values = {
    expenseIds: Array,
  }
  connect() {
    this.expenseProgressMapping = {};
    this.expenseIdsValue.forEach((id) => {
      this.expenseProgressMapping[id] = false;
    });
  };

  setSuccess(e) {
    const id = e.detail.id;

    this.expenseProgressMapping[id] = true;

    let allExpensesFinished = true;
    for (const [_id, finished] of Object.entries(this.expenseProgressMapping)) {
      if (!finished) {
        allExpensesFinished = false;
      }
    }

    if (allExpensesFinished) {
      this.showExpenseLink(e);
      this.showSuccess();
    }
  }

  showExpenseLink(e) {
    const expenseReportId = e.detail.expenseReportId;
    const link = document.getElementById("expense_link");
    if (expenseReportId && link) {
      link.href += expenseReportId;
      link.classList.remove("tw-hidden");
    }
  };

  showSuccess() {
    this.successTarget.classList.remove("tw-hidden");
    this.savingTarget.classList.add("tw-hidden");
  };

  showError(e) {
    this.errorTarget.classList.remove("tw-hidden");
    this.savingTarget.classList.add("tw-hidden");
    if (this.errorMessagesTarget) {
      const messages = e.detail.errors;
      if (messages !== null && messages.length > 0) {
        messages.forEach((message) => {
          const errorItem = document.createElement("li");
          errorItem.appendChild(document.createTextNode(message));
          this.errorMessagesTarget.appendChild(errorItem);
        });
      }
    }
  }
}
