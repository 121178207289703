import { Controller } from "@hotwired/stimulus";
import { initDatePickerInBlock } from "../../src/custom/DatetimeInit";
import { initAccordion } from "../../src/custom/components/Accordion";

export default class extends Controller {
  static targets = ["expenseTypeName"];

  connect = () => {
    initDatePickerInBlock(this.element);
    this.element.querySelectorAll('[data-toggle]').forEach((el) => {
      initAccordion(el);
    });
  };

  setExpenseTypeName = (e) => {
    e.stopPropagation();
    if (e.detail.field_name === "expense_type") {
      this.expenseTypeNameTarget.value = e.detail.name;
    }
  };
}
